.search-pagination {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-radius: 20px;
    background-color: none;
    border-style: none;
    padding-top: .5rem;
    border-width: 0px;
    border-color: #404040;
    padding-bottom: 1rem;
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.search-pagination>li{
    list-style-type: none;
    outline-style: solid;
    outline-width: 1px;
    cursor: pointer;
    border-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-large;
    min-height: 50px;
    outline-color: #ECECEC;
    min-width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-left: 5px;
    color: #ECECEC;
    margin-right: 5px;
}
.selected {
    background-color: #000000;
    outline-color: #404040;
    border: none;
    color: #ffef8a;

}
.search-pagination>li.disabled {
    color: #727272;
    outline: none;
    cursor: auto;
}
.search-pagination>li.disabled:hover {
    background-color: #404040;
    color: #727272;
}
.search-pagination>li:hover{
    background-color: #727272;
    color: black;
}

.search-pagination>li>a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 50px;
    width: 50px;
}
.pagination-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 25px;
    height: 25px;
    line-height: .5;
}
.pagination-icon::before{
    height: max-content;
    line-height: .5;
}