.section-default-yellow {
    background: #FFE11E;
    padding: -1rem;
    width: 100%
}
.search-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}
.main-search {
    border-radius: 20px;
    border-style: none;
    background-color: white;
    width: 100% !important;
    max-width: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 40px;
    align-self: center;
    margin-bottom: 0rem !important;
}
.main-search:focus-within {
    background-color: white;
    box-shadow: 0px 2px 12px -4px rgba(115,115,115,0.55);
}
.main-search:hover {
    box-shadow: 0px 2px 12px -4px rgba(115,115,115,0.55);
}
.main-search-input {
    background-color: white;
    border-style: none;
    /* border-radius: 20px; */
    font-family: "Segoe UI";
    width: 100%;
    max-width: none;
    font-weight: 400;
    font-size: 20px;
    margin: 3px;
    margin-right: 30px;
    color: #404040;
    padding: 0px 0px;
    position: relative;
    right: 15px;
    left: 15px
  }
  .search-label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ECECEC;
    font-weight: 400;
    text-align: left;
    font-size: large;
    padding: 0px 0px;
    padding-left: .5rem;
    padding-right: 1rem;
    margin: 0px;
  }
  .search-icon {
    font-size: 24px;
    color: #ececec;
    position: relative;
    right: 10px;
    text-align: right;
    justify-content: right;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: .2s;
    margin: 0px;
    padding-bottom: 0px;
  }
  
  .search-icon:hover {
    color: #000000;
  }
  .search-icon-rotated {
    /* display: inline-block;    */
    -webkit-transform: rotate(-22deg);
    -moz-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    -o-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  .main-search-input:focus-within {
    border-style: none;
    outline: none;
    background-color: rgba(0,0,0,0.0);
  }
  .main-search-input:focus {
    outline: none;
  }
  
.preview-container {
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 5px;
}
.search-header {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: x-large;
  padding-top: 1rem;
  padding-left: 15px;
  padding-right: 15px;
  color: #ECECEC;
}

@media screen and (max-width: 750px) {
  .search-label{
    display: none;
  }
}

.browse-header {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: xx-large;
  text-align: left;
  width: 100%;
}

.explore-gallery-prompt {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #ECECEC;
  font-weight: 500;
  font-size: medium;
  display: flex;
  cursor: pointer;
  margin: 0;
}
.explore-gallery-prompt:hover {
  text-decoration: underline;
}
.explore-gallery-prompt-icon {
  color: #ECECEC;
}
.browse-pill {
  border-radius: 50px !important;
  cursor: pointer;
  border-style: solid;
  border-color: #ECECEC;
  border-width: 2px;
  margin: .3rem;
  font-size: medium;
  transition: .25s;
  color: #ECECEC !important;
}
.browse-pill:hover {
  background-color: #727272;
}

.active {
  background-color: rgb(41, 41, 41) !important;
}