.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}
.login-label {
    justify-content: left;
    text-align: left;
    width: auto;
    margin-left: 10px;
    color: #ECECEC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.login-form-group{
    width: 50%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}

.login-email-input {
    min-width: 40%;
}

.password-input {
    min-width: 40%;
}

.login-button {
    background: #FFE11E;
    border: solid;
    border-radius: 5px;
    border-width: 1px;
    font-size: medium;
    max-width: 50%;
    min-width: 25%;
    position: relative;
    right: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: block;
}
.login-button-group{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.signup-link {
    color:#ECECEC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
}

.reset-password-header {
    color: white;
    font-size: x-large;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 2rem;
}
.reset-modal-close-icon {
    float: right;
    margin-bottom: 15px;
    color: #FFE11E;
    font-size: x-large;
    text-align: right;
    align-self: flex-end;
    cursor: pointer;
}
.reset-modal-close-icon:hover {
    color: white;
}
.login-error-message {
    color: red;
    font-size: medium;
    text-align: left;
    width: 100%;
}

@media screen and (max-width: 850px) {
    .login-form-group {
        width: 100%;
    }
    .login-button-group {
        width: 100%;
    }
}