.ai-search-input {
    border-radius: 10px;
    border-style: none;
    background-color:#727272;
    width: 80%;
    max-width:600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 40px;
    align-self: center;
    box-shadow: 0px 2px 8px -2px rgba(39, 39, 39, 0.75);
    margin-bottom: 3rem;
    border-width: 1px;
    border-color: #ececec;
    border-style: solid;

}

.ai-search-error {
    color: rgb(248, 128, 128);
    font-size: small;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ai-search-input:focus-within {
    background-color: #7a7a7a;
    border-color: #ECECEC;
    border-width: 1.5px;
    box-shadow: 0px 2px 8px -2px rgba(39, 39, 39, 0.75);
}
.ai-search-input:hover {
    box-shadow: 0px 2px 8px -2px rgba(39, 39, 39, 0.75);
}
.ai-search-input-input {
    background-color: #727272;
    border-style: none;
    /* border-radius: 20px; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    margin: 3px;
    margin-right: 30px;
    color: #ECECEC;
    padding: 0px 0px;
    position: relative;
    right: 15px;
    left: 15px
  }
  .ai-search-icon {
    font-size: 24px;
    color: #ececec;
    position: relative;
    right: 10px;
    text-align: right;
    justify-content: right;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: .2s;
    margin: 0px;
    padding-bottom: 0px;
  }
  
  .ai-search-icon:hover {
    color: white;
  }
  .ai-search-icon-rotated {
    /* display: inline-block;    */
    -webkit-transform: rotate(-22deg);
    -moz-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    -o-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  .ai-search-input-input:focus-within {
    border-style: none;
    outline: none;
    background-color: rgba(0,0,0,0.0);
  }
  .ai-search-input-input:focus {
    outline: none;
  }

  .tips {
    color: #ececec;
    font-style: italic;
    font-size: small;
    text-align: left;
  }

  @media screen and (max-width: 750px) {
    .ai-search-input{
      width: 100%;
      margin-bottom: 0rem
    }
  }


  