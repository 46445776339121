.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}
.section-darkgray {
    background: #404040;
    padding: 1rem;
    width: 100%
}
.section-midgray {
    background: #727272;
    padding: 1rem;
    width: 100%
}
.section-white {
    background: #FFFFFF;
    padding: 1rem;
    width: 100%
}
.profile-name{
    font-size: xx-large;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    line-height: 40px;
    margin: 0px;
}
.profile-hr {
    padding-bottom: 0px;
    margin-bottom: 0px;
    height: 1px;
    background: #e8e8e8;
}

.disabled-link {
    pointer-events: none;
}
.edit-profile-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    background: #FFE11E;
    min-height: 36px;
    margin: 10px;
    min-width: 110px;
    border-radius: 18px;
    display: block;
    margin-bottom: 0px;
    outline: none;
    border: none;
    color:#282c34;
    float: left;
  }
  .edit-profile-button:hover {
    background: #ECECEC;
    color: black;
  }
  .edit-profile-button:focus {
    outline: none;
    box-shadow: none;
  }
  .edit-profile-button:disabled{
    background: #ECECEC;
    color: black;
  }
  .profile-detail{
    font-size: large;
    font-style: normal;
    color: #ECECEC;
    line-height: 1;
    padding-left: 15px;
  }
  .profile-detail-icon{
    font-size: xx-large;
    font-style: normal;
    color: #fff187;
    line-height: 1;
    margin-bottom: .75em;
  }
  .preview-container {
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 5px;
}

.delete-modal {
  border-radius: 20px;
  max-width: 90%;
  border: none;
  box-shadow: 0px 1px 29px -14px rgba(0,0,0,0.75);
  background: #404040;
}

.delete-modal-close-icon {
  float: right;
  font-size: 22px;
  cursor: pointer;
  color: rgb(250, 250, 250);
  transition: .25s;
}

.delete-modal-close-icon:hover {
  transform:scale(1.2)
}

.button-tiny-font {
  font-size: large;
}

@media screen and (max-width: 750px) {
  .profile-strike > span {
    right: 0%;
  }
  .profile-name {
    font-size: x-large !important;
  }
  .button-tiny-font {
    font-size: medium;
  }
  
}
