.theme-preview{
    border-radius: 20px;
    min-width: 150px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: #ECECEC;
    margin: 5px;
    background: #727272;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ECECEC;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow:visible;
}
.theme-preview:hover{
    background: rgba(41, 41, 41, 1);
    color: white;
}
.Link{
    text-decoration: none;
}
.Link:active{
    text-decoration: none !important;
}
.theme-preview-name{
    padding:0;
    margin: 0;
    color: inherit;
    font-weight: 350;
    margin-left: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: large;
    text-decoration: none;
}
.theme-preview-name:active:hover{
    text-decoration: none !important;

}
.theme-preview-palette-container{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
    border-radius: 10px;
    border-style: solid;
    border-color: white;
    border-width: 0px;
    min-height: 28px;
    overflow: hidden;
    box-shadow: 1px 1px 18px -4px rgb(28, 28, 28);
}
.theme-preview-palette-cell{
    flex: 1;
    display: block;
    padding: 0;
    margin: 0;
    height: 40px;
    min-width: 12px;
    border-style: none;
}
.theme-preview-arrow{
    font-size: xx-large;
    position: absolute;
    float: right;
    right: 10px;
    display: none;
}
.theme-preview-arrow-visible{
    display: flex;
}

.theme-preview-lock {
    font-size: medium;
    display: inline-block;
}

@media screen and (max-width: 750px) {
    .theme-preview{
        padding-left: 6px;
        padding-right: 6px;
        min-width: 50px;
    }
    .theme-preview-palette-cell{
        min-width: 5px;
        height: 30px;
    }
    .theme-preview-name {
        font-size: small;
    }
    
}