.generate-card {
    margin-top: 1rem;
    padding: 1rem;
    border-style: none;
    border-width: 1.5px;
    border-radius: 15px;
    background: #727272;
    width: 100%;
}
.generate-card-col {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    padding-left: 10rem;
    padding-right: 10rem;
    width: 100%;
    margin-top: 3rem;
}
.generate-palette-container{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 20%;
    border-radius: 15px;
    border-style: solid;
    border-color: white;
    border-width: 0px;
    overflow: hidden;
    box-shadow: 1px 1px 21px -4px rgb(41, 41, 41);
}

.generate-palette-cell{
    flex: 1;
    display: block;
    padding: 0;
    margin: 0;
    height: 30px;
    min-width: 10px;
    border-style: none;
}

.prompt {
    color: #ECECEC;
    margin-top: .5rem;
    font-style: italic;
    text-align: left;
  }
  .prompt-prefix {
    color: #ECECEC;
    margin-top: .5rem;
    font-style: normal;
    text-align: left;
    font-weight: 600;
  }

@media screen and (max-width: 800px) {
    .generate-palette-container {
        min-width: 30%;
    }
    .generate-card-col {
       padding-left: 0rem;
       padding-right: 0rem;
    }
    
}

.generate-card-name {
    color: #ECECEC;
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    text-align: left;
    padding: 1rem;
}