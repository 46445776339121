.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}
.section-gray {
    background: #727272;
    padding: 1rem;
    padding-top: 110px;
    width: 100%;
    margin-top: -100px;
    min-height: 200px;
}
.theme-view-name {
    font-size: xx-large;
    padding-left: 1rem;
    padding-top: .5rem;
    color: #ECECEC
}
.theme-view-card {
    position: relative;
    background: #727272 !important;
    background-color: white;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 7px 8px 17px -7px rgba(66,66,66,0.5);
    padding-bottom: 15px;
}
.palette-container{
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    box-shadow: 1px 1px 7px -1px rgba(41,41,41,0.68);
}
.palette-cell{
    flex: 1;
    display: block;
    min-height: 250px;
    border-style: none;
}
.theme-detail{
    font-size: large;
    font-weight: 400;
    color: #ECECEC;
    text-decoration-color: ECECE;
}
.like-theme-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    display: inline;
    font-size: 2rem;
    cursor: pointer;
    color: #FFE11E;
    transition: .3s;
    -webkit-transform-origin: 50% 42.5%;
    -moz-transform-origin: 50% 42.5%;
    -o-transform-origin: 50% 42.5%;
    transform-origin: 50% 42.5%;
}
.like-theme-icon:hover {
    color: #FFE11E;
    transform: scale(1.1) rotate(17deg);
}
.button-group {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}
.view-download-theme-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: #FFE11E;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#282c34;
  }
  .view-download-theme-button:hover {
    background: #000000;
    color: white;
  }
  .view-download-theme-button:focus {
    outline: none;
    box-shadow: none;
  }

  .edit-theme-button {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: #ECECEC;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 0px;
    border-style: solid;
    color:#000000;
  }
  .edit-theme-button:hover {
    background: #000000;
    color: white;
  }
  .edit-theme-button:focus {
    outline: none;
    box-shadow: none;
  }
  .tag-container {
    width: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: left;
    align-items:flex-start;
    flex-wrap: wrap;
  }
  .theme-tag {
    background: #9b9a9a;
    color: white;
    border-radius: 5px;
    border-style: none;
    margin: 3px;
    padding: 3px;
    padding-right: 10px;
    font-size: medium;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    padding-left: 10px;
  }
 

  .share-switch-label {
    font-size: large;
    color: #ececec;
  }

  .share-switch {
    transform: scale(2) !important;
  }

  .try-link {
    background-color: #404040;
    border-style: solid;
    border-width: 2px;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    font-size: medium;
    padding-left: 3px;
  }
  .try-link:hover {
    background-color: #FFE11E;
    color: black;
    border-color: #FFE11E;
  }
  .try-link-active{
    background-color: #FF7236;
    border-color: #FF7236;
    color: white
  }

  .custom-control-input:focus~.custom-control-label::before {
    border-color:#000000 !important;
    box-shadow: none;
  }
  
  .custom-control-input:checked~.custom-control-label::before {
    border-color: #000000 !important;
    background-color: #000000 !important;
  }
  
  .custom-control-input:active~.custom-control-label::before {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
  
  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #000000 !important;
  }
  
  .custom-control-input-green:not(:disabled):active~.custom-control-label::before {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
.delete-icon {
  cursor: pointer;
  font-size: 30px;
  color: #fbdede;
}
.delete-icon:hover {
  color: #fd2c2c;
}