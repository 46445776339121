.tip-callout {
    text-align: left;
    font-size: large;
    padding-left: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    color: #ECECEC;
}

.tip-accordion{
    align-items: left;
    border: solid;
    border-radius: 22px;
    margin-top: 2rem;
    border-color: #727272;
    margin-left: 5rem;
    margin-right: 5rem;
}
.tip-text {
    text-decoration: none !important;
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ECECEC;
}
.tip-h {
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-items: left;
    align-items: center;
    align-content: center;
    text-decoration: none;
}
.tip-header {
    background-color: #404040;
    display: flex;
    align-items: left;
    justify-content: left;
    justify-items: left;
    cursor: pointer;
    color: #ECECEC;
}
.tip-card {
    background-color: #404040;
    border-radius: 22px;
    overflow: hidden;
    border-color: #404040;
    margin: 0px;
    border-width: 0px;
}

.tip-icon {
    color: #fae769;
    font-size: xx-large;
}
.tip-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    
}
.tip-button:hover {
    text-decoration: none;
}
.tip-button>a:focus {
    text-decoration: none;
}
.btn-link:focus {
    text-decoration: none;
}
.tip-card-body {
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
}

@media screen and (max-width: 800px) {
    .tip-accordion {
        margin: 0rem;
        margin-top: 2rem;
    }
}
