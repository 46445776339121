.perk-callout {
    text-align: left;
    font-size: large;
    padding-left: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    color: #ECECEC;
}

.perk-accordion{
    align-items: left;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
.perk-text {
    text-decoration: none !important;
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ECECEC;
}
.perk-h {
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-items: left;
    align-items: center;
    align-content: center;
    text-decoration: none;
}
.perk-header {
    background-color: rgb(41, 41, 41);;
    display: flex;
    align-items: left;
    justify-content: left;
    justify-items: left;
    cursor: pointer;
    color: #ECECEC;
}
.perk-card {
    background-color: rgb(41, 41, 41);;
    border-radius: 20px;
    overflow: hidden;
    border-color: #404040;
    margin-top: 10px;
    margin-bottom: 10px;
}
.perk-card:hover{
    box-shadow: 0px 1px 29px -14px rgba(0,0,0,0.75);
}
.perk-icon {
    color: rgb(145, 228, 145);
    font-size: xx-large;
}
.perk-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    
}
.perk-button:hover {
    text-decoration: none;
}
.perk-button>a:focus {
    text-decoration: none;
}
.btn-link:focus {
    text-decoration: none;
}
.perk-card-body {
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
}