.section-default-yellow {
    background:#FFE11E;
    padding: 1rem;
    width: 100%;
}
.section-default-white {
    background:#FFFFFF;
    padding: 1rem;
    width: 100%
}
.section-darkgray {
    background: #404040;
    padding: 1rem;
    width: 100%
}
.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}

.section-medium-gray {
    background: #727272;
    padding: 1rem;
    width: 100%;
}

.section-superdark {
    background: rgb(41, 41, 41);;
    padding: 1rem;
    width: 100%;
}