
.action-container{
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: space-evenly;
    padding: 0px;
    padding-top: 1rem;
    width: 100%;
    margin: 0;
    transition: .3s;
  }
  .action-card {
    flex: 1;
    background: rgb(41, 41, 41);
    position: relative;
    border-width: 1px;
    border-radius: 20px;
    min-width: 200px;
    min-height: 205px;
    box-shadow: none;
    transition: .3s;
    transform-origin: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    text-decoration: none !important;
    justify-content: space-between;
  }
  .action-card-hovered{
    box-shadow: 7px 8px 17px -7px rgba(66,66,66,0.5);
  }
  .action-card-header{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: large;
    text-align: left;
    padding: 1rem;
    padding-left: 1.1rem;
    padding-bottom: .2rem;
    color: #ECECEC;
  }

  .action-card-description {
    color: #ECECEC;
    font-size: small;
    text-align: left;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .action-card-icon{
    position: relative;
    font-size: 3rem;
    transition: .5s;
    color: #fff187;
  }

  .action-card-icon-hovered{
    transform: scale(1.2) rotate(-9deg);
  }
  .action-card-button {
    font-size: medium;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: white;
    height: 36px;
    border-radius: 18px;
    color:black;
    position: absolute;
    bottom: -5%;
    left: 50%;
    border-width: 1px;
    border-style: solid;
    transition: .25s;
    transform: translate(-55%, -50%);
    white-space: nowrap;
  }
  .action-card-button:hover {
    background:black;
    color: white;
    transform: translate(-55%, -50%) scale(1.1);
  }
  .action-card-button:focus {
    outline: none;
    box-shadow: none;
  }

  .action-card-new-tag{
    position: absolute;
    top: -12px;
    left: -10px;
    background: #FFE11E;
    color: #404040;
    padding: .1rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: small;
    font-weight: 600;
    transform: rotate(-10deg);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 1rem;
  }

@media screen and (max-width: 750px) {
    .action-card-icon{
      position:relative;
      display: inline-block;
      font-size: 2rem;
      transition: .5s;
      color: #fff187;
      padding: .5rem;
      padding-bottom: 2rem;
    }
    .action-card{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-items: center;
      justify-content: space-between;
      min-width :min-content;
      margin: 0px;
      padding: 0px;
      min-height: 140px;
      margin-right: 5px;
      margin-left: 5px;
    }
    
    .action-card-header{
      font-size: medium;
    }

    .action-card-description {
      display: none;
    }
  
    .action-container {
      width: 100%;
      display: flex;
      flex-flow: nowrap;
      justify-content: space-evenly;
    }
    .action-card-button{
      position: relative;
      display: block;
      transform: translate(-50%,  110%);
      margin: 1px;
      padding: .3rem;
      font-size: 12px;
      height: min-content;
      display: none;
    }
    .action-card-button:hover {
      background:black;
      color: white;
      transform: translate(-50%, 110%);
    }
    
}