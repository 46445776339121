.theme-preview-card{
    border-radius: 20px;
    min-width: 150px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: solid;
    border-width: -0px;
    border-color: #ECECEC;
    margin: 5px;
    background: rgb(41,41,41);
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow:visible;
    height: 90%;
    color: #ECECEC;
}
.theme-preview-card:hover{
    background:rgb(41,41,41);;
    color: white;
    transform: scale(1.1);
    z-index: 999;
    box-shadow: 1px 1px 25px -10px rgb(20, 20, 20);;
}
.Link{
    text-decoration: none;
}
.Link:active{
    text-decoration: none !important;
}
.theme-preview-card-name{
    padding:0;
    margin: 0;
    color: inherit;
    width: 85%;
    padding-top: .25rem;
    font-weight: 350;
    margin-left: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: large;
    text-decoration: none;
    text-align: left;
    font-size: x-large;
    padding-bottom: .25rem;
}
.theme-preview-card-name:active:hover{
    text-decoration: none !important;

}
.theme-preview-card-palette-container{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 85%;
    border-radius: 10px;
    border-style: solid;
    border-color: white;
    border-width: 0px;
    min-height: 28px;
    overflow: hidden;
    box-shadow: 1px 1px 18px -4px rgb(28, 28, 28);
}
.theme-preview-card-palette-cell{
    flex: 1;
    display: block;
    padding: 0;
    margin: 0;
    height: 40px;
    min-width: 12px;
    border-style: none;
}
.theme-preview-card-card-arrow{
    font-size: xx-large;
    position: absolute;
    float: right;
    right: 10px;
    display: none;
}
.theme-preview-card-arrow-visible{
    display: flex;
}

.theme-preview-card-lock {
    font-size: medium;
    display: inline-block;
}
.theme-preview-card-detail {
    font-size: medium;
    font-weight: 450;
    width: 100%;
    text-align: left;
}
.theme-preview-card-detail-icon {
    display: inline-block;
}
.card-tag {
    display: flex;
    padding: .3rem;
    margin: .1rem;
    line-height: 1;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 375;
    font-size: small;
    flex-direction: row;
    align-content: center;
    background-color: #727272;
    border-radius: 3px;
    color: white;
}

@media screen and (max-width: 750px) {
    .theme-preview-card{
        padding-left: 6px;
        padding-right: 6px;
        min-width: 50px;
    }
    .theme-preview-card-palette-cell{
        min-width: 5px;
        height: 35px;
    }
    
}