
.add-color-button {
    height: 145px;
    margin: 3px;
    width: 136px;
    border-radius: 10px;
    background: none;
    /* box-shadow: 0 0  3px -1px rgb(64, 64, 64); */
    outline: none;
    border: #727272;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    outline-width: 1px;
    border-style: dashed;
}
.add-color-button:hover {
    box-shadow: 0 0  3px -1px rgb(64, 64, 64);
}
.add-icon {
     display: inline-block;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-size: xx-large;
    color: #fff187;
    transition: .4s;
    margin: 0;
    padding: 0;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%; 
}
.add-icon-rotated {
    transform: rotate(-20deg);
}



.edit-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 2px;
}

.color-card-container {
    padding: 1rem;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}


@media screen and (max-width: 800px) {
    .edit-container {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 2px;
        margin-right: auto;
        margin-left: auto;
    }

    .color-card-container {
        justify-content: center;
    }

    .add-color-button {
        height: 95px;
        width: 90px;
    }
}