.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}
.section-darkgray {
    background: #404040;
    padding: 1rem;
    width: 100%
}
.section-default-yellow {
    background:#FFE11E;
    padding: 1rem;
    width: 100%
}
.signup-label {
    justify-content: left;
    text-align: left;
    width: auto;
    color: #ECECEC;
    margin-left: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.signup-form-group{
    width: 50%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}
.signup-email-input {
    min-width: 40%;
}

.signup-password-input {
    min-width: 40%;
}

.signup-button {
    background: #000000;
    border: solid;
    color: white;
    border-radius: 5px;
    border-width: 0px;
    font-size: medium;
    max-width: 50%;
    min-width: 25%;
    padding: 3px;
    position: relative;
    right: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: block;
}
.signup-button:hover {
    background: #727272;
    color:white;
}
.signup-button:disabled {
    color: #727272;
    background: #000000;
}
.signup-button-group{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.login-link {
    color:#ECECEC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
}

.signup-error-small {
    color: red;
    font-size: medium;
    text-align: left;
}

@media screen and (max-width: 850px) {
    .signup-form-group {
        width: 100%;
    }
    .signup-button-group {
        width: 100%;
    }
}