.section-lightgray {
    background: #ECECEC;
    padding: 1rem;
    width: 100%
}
.section-darkgray {
    background: #404040;
    padding: 1rem;
    width: 100%
}
.section-midgray {
    background: #727272;
    padding: 1rem;
    width: 100%
}
.section-white {
    background: #FFFFFF;
    padding: 1rem;
    width: 100%
}
.profile-name{
    font-size: xx-large;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.signup-form-group{
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
}
.edit-profile-input {
    font-size: 16px !important;
    height: 40px;
    border-radius: 7px;
    min-width: 40%;
    border: solid;
    border-color: black;
    border-width: 1px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.edit-profile-input:focus {
    border-color: black !important;
    border-width: 1px !important;
    outline-color: black;
    outline-style: solid;
    outline-width: 2px;
    box-shadow: none;
}
.save-profile-changes-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: #FFE11E;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#282c34;
  }
.save-profile-changes-button:hover {
background: #000000;
color: white;
}
.save-profile-changes-button:disabled {
    background: #727272;
}
.save-profile-changes-button:disabled:hover {
    color: black;
}
.save-profile-changes-button:focus {
outline: none;
box-shadow: none;
}
.button-gray {
    background:#404040;
    color: white;
}
