.home-title {
    font-size: x-large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: white;
    padding: 5px;
    font-weight: 400;
}
.home-section-default-white {
    background:#FFFFFF;
    padding: 1rem;
    width: 100%
}
.home-section-default-yellow {
    background: #FFE11E;
    padding: 1rem;
    width: 100%
}

.black-primary-button {
    font-size: medium;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;

    background: #000000;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#FFFFFF;
  }
  .black-primary-button:hover {
    background: #727272;
    color: white;
  }
  .black-primary-button:focus {
    outline: none;
    box-shadow: none;
  }
  
  .preview-container {
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 5px;
}

.landing-blurb {
  line-height: 1;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0;
  font-size: x-large;
  display: inline-block;
  color: #ECECEC
}
.landing-blurb-span {
  font-size: x-large;
  color: #ECECEC;
}
.landing-blurb-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
 
  .landing-blurb {
    font-size: large;
  }
  .a:hover {
    text-decoration: none;
  }
  
}
.landing-empty-gallery-prompt {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #404040;
  font-weight: 500;
  font-size: medium;
  display: flex;
  cursor: pointer;
}
.landing-empty-gallery-prompt:hover {
  text-decoration: underline;
}
.landing-gallery-prompt-icon {
  color: #404040;
}
