.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}

.strike > span {
    position: relative;
    right:25%;
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 750px) {
  .strike > span {
    right: 0%;
  }
  
}

.default-input {
    font-size: large;
    height: 40px;
    border-radius: 10px;
    min-width: 40%;
    padding-left: 10px;
    border: solid;
    border-color: white;
    border-width: 1px;
    background: #727272;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}
.default-input:focus {
    border-color: #404040 !important;
    box-shadow: 0 0 5px 0 rgb(64, 64, 64) !important;
}
.default-input::placeholder {
    color: #dedede;
}

.default-input:focus::placeholder {
    color: #404040;
    font-weight: 275;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #404040 !important;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.strike-white > span:before,
.strike-white > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #ffffff !important;
}

.icon-centered {
    display: inline-flex;
    align-items: center;
    justify-items: center;
}
.icon-centered::before {
    align-self: center;
    justify-self: center;
}
.strike-text-black {
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.strike-text-white {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.strike-text-yellow {
    color: #fff187;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dark-mode-fluid {
    background-color: #404040;
}

.open-ai-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    color: #f1f1f1;
    font-size: x-large;
    padding-bottom: 1.5rem;
}

.color-selector-small {
    background: #727272;
    width: 150px;
    cursor: pointer;
    height: 50px;
    box-shadow: 0 0  3px -1px rgb(64, 64, 64);
    border: none;
}

.color-selector-small-short {
    background: #727272;
    width: 150px;
    cursor: pointer;
    height: 40px;
    box-shadow: 0 0  3px -1px rgb(64, 64, 64);
    border: none;
}

