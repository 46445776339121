
.bot {
    min-height: 400px;
    border-radius: 10px;
    border-width: 1px;
    width: 100%;
    border-style: solid;
    margin-top: .75rem;
    padding: 1rem;
    background-color: white;
}
.tip-header {
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    text-align: left;
}
.tip-span {
    display: inline-block;
    border-style: solid;
    padding: .25rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: medium;
    width: fit-content;
    margin-top: .5rem;
    border-width: 1px;
    border-radius: 18px;
    background-color: #f9ed9d;
    color: #404040;
    cursor: pointer;
    margin-right: .25rem;
    border-color: #404040;
    transition: .3s;
}
.tip-span:hover {
    background-color: #727272;
    color: white;
}
.bot-title {
    font-size: x-large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ECECEC;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: .25rem;
    margin-top: .25rem;
}

.demo-gif {
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
}