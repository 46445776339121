.recent-pill-title {
    color: #ececec;
    font-size: large;
    padding-top: 2rem;
    font-weight: 400;
}
.recent-pill {
    background: rgb(41,41,41);
    display: inline;
    padding: .4rem;
    padding-top: .15rem;
    padding-bottom: .2rem;
    color: #ececec;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: medium;
    border-radius: 8px;
    box-shadow: 0px 0px 8px -2px rgba(36, 36, 36, .5);
    cursor: pointer;
    transition: .5s;
    margin: 5px;
}

.recent-pill:hover {
    background: #727272;
    box-shadow: 0px 0px 12px 3px rgba(36, 36, 36, 1);
}

.recent-pill-container {
    display: flex;
    justify-content: center;
    padding: .5rem;
    flex-wrap: wrap;
}

.recent-pill-text {
    margin-bottom: 0;
    margin-top: 0;
}

@media screen and (max-width: 800px) {
    .recent-pill {
        font-size: small;
    }
}