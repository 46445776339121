.theme-link{
    height: 54px;
    border-radius: 27px;
    width: 100%;
    padding-left: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: #ECECEC;
    margin-top: 10px;
    background: rgb(41, 41, 41);
    transition: .3s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: #ECECEC;
    position: relative;
}
.theme-link:hover{
    background:#727272;
    color: white;
}
.Link{
    text-decoration: none;
}
.Link:active{
    text-decoration: none !important;
}
.theme-link-name{
    padding:0;
    margin: 0;
    color: inherit;
    font-weight: 400;
    margin-left: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: large;
    text-decoration: none;
}
.theme-link-name:active:hover{
    text-decoration: none !important;

}
.theme-link-palette-container{
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 20%;
    border-radius: 10px;
    border-style: solid;
    border-color: white;
    border-width: 0px;
    overflow: hidden;
    box-shadow: 1px 1px 18px -4px rgb(28, 28, 28);
}

.theme-link-palette-cell{
    flex: 1;
    display: block;
    padding: 0;
    margin: 0;
    height: 28px;
    min-width: 15px;
    border-style: none;
}
@media screen and (max-width: 800px) {
    .theme-link-palette-container {
        min-width: 30%;
    }
    
}
.theme-link-arrow{
    font-size: xx-large;
    position: absolute;
    float: right;
    right: 10px;
    display: none;
}
.theme-link-arrow-visible{
    display: flex;
}