.edit-tag-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.tag-div {
    background-color:#727272;
    color: white;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 3px;
    border-radius: 5px;
    font-size: large;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
}
.tag-text {
    font-size: large;
    font-weight: 450;
    margin-bottom: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.tag-icon {
    margin-left: 5px;
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: .3s;
}
.tag-icon::before {
    align-self: center;
}
.tag-icon:hover {
    color: rgb(255, 82, 82);
}
.tag-add-button {
    cursor: pointer;
    background-color: #727272;
}
.tag-add-button:hover {
    background: #a4a4a4;
    color: #FFFFFF;
}
.tag-add-button-icon  {
    font-size: x-large;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-items: center;
    color: white;
}
.tag-add-button-icon::before {
    align-self: center;
    justify-self: center;
}
.tag-edit-input {
    font-size: medium;
    max-height: 30px;
    border-radius: 3px;
    border-color: #727272;
    background-color: #dcdcdc;
    border: none;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 100px;
}

.tag-edit-icon  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 30px !important;
    height: 30px !important;
    font-size: 20px;
    cursor: pointer;
    border-radius: 3px;
    transition: .3s;
}
.tag-edit-icon:hover {
    background-color: #727272;
}

.tag-edit-icon::before  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 30px !important;
    height: 30px !important;
}

@media screen and (max-width:800px) {
    .edit-tag-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}