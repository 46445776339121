.create-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-large;
    font-weight: 400;
    color: #ECECEC;
}

.theme-name-input {
    font-size: large;
    height: 40px;
    border-radius: 10px;
    min-width: 40%;
    padding-left: 10px;
    border: solid;
    border-color: white;
    border-width: 1px;
    background: #727272;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;

}
.theme-name-input:focus {
    border-color: #404040 !important;
    box-shadow: 0 0 5px 0 rgb(64, 64, 64) !important;
}
.add-color-button .add-color-button:active {
    height: 145px;
    margin: 3px;
    width: 136px;
    border-radius: 10px;
    background: none;
    /* box-shadow: 0 0  3px -1px rgb(64, 64, 64); */
    outline: none;
    border: #727272;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    outline-width: 1px;
    border-style: dashed;
}
.add-color-button:hover {
    box-shadow: 0 0  3px -1px rgb(64, 64, 64);
}

.color-card-container {
    padding: 1rem;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.add-icon {
    display: inline-block;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-size: xx-large;
    color: #fff187;
    transition: .4s;
    margin: 0;
    padding: 0;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%; 
}
.add-icon-rotated {
    transform: rotate(-20deg);
}
.color-card {
    background: #727272;
    border-radius: 10px;
    padding: .5rem;
    min-height: 80px;
    box-shadow: 0 0  3px -1px rgb(64, 64, 64);
    transition: .3s;
}

.color-card-deleted {
    background: #FFE11E !important;
}

.color-card-deleted > *  {
    background: #FFE11E !important;
}

.color-card-deleted > * > * {
    background: #FFE11E !important;
}


.color-card-controls {
    display: flex;
    color: #ECECEC;
    justify-content: space-between;
    max-width: 100%;
    margin: 0 auto;
    transition: .3s;
    background: #727272;
}
.color-card-control-icon {
    color: #ECECEC;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
    border-radius: 5px;
    padding-top: 3px;
    transition: .3s;
    background: #727272;
}
.color-card-control-icon:hover {
    background: #FFE11E;
    color: black
}
.font-check {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
}
.font-check-label {
    color: white;
}
.font-input {
    font-size: large;
    height: 40px;
    border-radius: 10px;
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid;
    color: white;
    border-color: black;
    border-width: 1px;
    background: #727272;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.create-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 2px;
}

.font-configuration-container {
    margin-bottom: 1.3rem;
}
.font-configuration-header {
    text-align: left;
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ECECEC;
}
.font-configuration-group {
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
}
.font-form-label {
    color:#ECECEC;
}
.font-form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 1rem;
}
.advanced-font-face-select {
    font-size: large;
    height: 40px;
    border-radius: 5px;
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid;
    border-color: black;
    border-width: 1px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.color-card-selector {
    background: #727272;
    border-radius: 7px;
    margin: 0px;
    border: none;
    min-width: 120px;
    max-width: 150px;
    cursor: pointer;
    height: 100px;
    transition: .3s;
    margin-bottom: .25rem;
}

.color-card-selector:active {
    background: #727272;
}

@media screen and (max-width: 800px) {
    .create-container {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 2px;
        margin-right: auto;
        margin-left: auto;
    }

    .color-card-container {
        justify-content: center !important;
        margin-left: 0px;
        margin-right: 0px;
    }

    .color-card {
        min-height: 40px;
    }

    .color-card-selector {
        height: 50px;
        border-radius: 10px;
        min-width: 75px;
        width: 75px;
    }

    .add-color-button {
        height: 75px;
        width: 75pxpx;
    }
    
}
