.hidden {
    display: none;
}
.extract-title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-large;
    font-weight: 400;
    color: #ECECEC;
}

.upload-target {
    border-radius: 25px;
    color: #ececec;
    padding: 1rem;
    background-color: #727272;
    background: #727272;
    border-style: dashed;
    stroke-dasharray: 10px;
    min-height:8rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    border-color: #ececec;
}

.drag-active {
    background-color: rgb(201, 201, 201);
    background: rgb(201, 201, 201);
}

.extract-target-text {
    font-size: large;
    color: #ECECEC;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 425;
    pointer-events: none;
}

::-webkit-file-upload-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: #FFE11E;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#282c34;
    cursor: pointer;
}
::-webkit-file-upload-button:hover {
    background: #000000;
    color: white;
}
::-webkit-file-upload-button:focus {
    outline: none;
    box-shadow: none;
}

::-webkit-file-upload-button:disabled {
    background-color: gray;
    cursor: default;
  }
  ::-webkit-file-upload-button:disabled:hover {
    background-color: gray;
    color: black;
  }
     

.create-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 2px;
}

.extract-column {
    padding-top: .5rem;
}
.extract-image-container {
    border-radius: 25px;
    overflow: hidden;
    width: 100%;
}

.extract-img {
    border-radius: 25px;
    width: 100%;
}
.extract-settings-container {
    border-radius: 25px;
    border-style: solid;
    min-width: 200px;
    height: 100%;
    border-width: 1.5px;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;
    border-color: #cdcdcd;
}

.extract-settings-header {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: x-large;
    color: #404040;
    font-weight: 400;
    color: #ECECEC
}
.extract-progress {
    width: 100%;
    margin-bottom: 0px;
    border-radius: 3px;
}
.extract-progress[value]::-webkit-progress-bar{
    background-color: #D9D9D9;
    border-radius: 5px;
    overflow: hidden;
    height: 10px;
}

.extract-progress[value]::-webkit-progress-value{
    background-color: 00B89F;
    border-radius: 5px;
    overflow: hidden;
    height: 10px;
}

.extract-think-fabric {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15;
    right: 15;
    background: #91919188;
    background-color: #91919188;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extract-think-spinner {
    width: 4rem;
    height: 4rem;
}

.extract-palette-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

}

.extract-palette-number-input {
    height: 40px !important;
    border-radius: 20px !important;
    border-color: #404040 !important;
}

.extract-palette-cell {
    min-height: 5rem;
    width: 100%;
    margin: 3px;
    margin-top: 10px;
    border-radius: 10px;
}

.extract-refresh-button {
    
    justify-self: flex-end;
    
    
}

.extract-settings-small {
    font-style: italic;
    color: #fff9d5;
}

@media screen and (max-width: 800px) {
    .create-container {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 2px;
        margin-right: auto;
        margin-left: auto;
    }
    
}
