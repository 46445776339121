.breadcrumb-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 0rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: .25rem;
}

.breadcrumb-pill {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    padding: .1rem;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #727272;
    border-radius: 8px;
}

.breadcrumb-text {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: small;
    margin-bottom: 0px;
    padding: 0px;
    color: white
}

.breadcrumb-divider {
    padding-right: .2rem;
    color: #ECECEC;
}