.yellow-default-button-primary {
  font-size: large;
  padding-left: 25px;
  padding-right: 25px;
  margin: 10px;
  background: #FFE11E;
  height: 36px;
  min-width: 110px;
  border-radius: 18px;
  outline: none;
  border: none;
  color:#282c34;
  width: fit-content;
  white-space: nowrap;
  transition: .3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.yellow-default-button-primary:disabled {
  background-color: gray;
  cursor: default;
}
.yellow-default-button-primary:disabled:hover {
  background-color: gray;
  color: black;
}
.yellow-default-button-primary:hover {
  background: #c0aa1a;
  color: black;
}
.yellow-default-button-primary:focus {
  outline: none;
  box-shadow: none;
}

.black-default-button-primary {
  font-size: large;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  margin: 10px;
  background: black;
  height: 36px;
  min-width: 110px;
  border-radius: 18px;
  outline: none;
  border: none;
  color:white;
}
.black-default-button-primary:hover {
  background: #FFE11E;
  color: #282c34;
}
.black-default-button-primary:focus {
  outline: none;
  box-shadow: none;
}

.black-default-button-primary:disabled {
  background-color: gray;
  cursor: default;
}
.black-default-button-primary:disabled:hover {
  background-color: gray;
  color: white;
}

.save-theme-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: #FFE11E;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#282c34;
  }
  .save-theme-button:hover {
    background: #000000;
    color: white;
  }
  .save-theme-button:focus {
    outline: none;
    box-shadow: none;
  }
  .discard-theme-button {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: white;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 2px;
    border-style: solid;
    color:#282c34;
  }
  .discard-theme-button:hover {
    background: #000000;
    color: white;
  }
  .discard-theme-button:focus {
    outline: none;
    box-shadow: none;
  }
  .download-theme-button {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: #727272;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 0px;
    border-style: solid;
    color:white;
  }
  .download-theme-button:hover {
    background: #000000;
    color: white;
  }
  .download-theme-button:focus {
    outline: none;
    box-shadow: none;
  }
  .download-theme-button:disabled {
    background-color: gray;
    cursor: default;
  }
  .download-theme-button:disabled:hover {
    background-color: gray;
    color: black;
  }

  .extract-refresh-button:disabled {
    background-color: lightgray;
    cursor: default;
  }
  .extract-refresh-button:disabled:hover {
    background-color: lightgray;
    color: black;
  }


  .header-login-button {
    font-size: large;
    background: #FFE11E;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:#282c34;
    white-space: nowrap;
    transition: .3s;
  }
  .header-login-button:hover {
    background: #c0aa1a;
    color: black;
  }
  .header-login-button:focus {
    outline: none;
    box-shadow: none;
  }
  .delete-confirm-button {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: none;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 2px;
    border-style: solid;
    color:#ffc7c2;
  }
  .delete-confirm-button:hover {
    background: #F25244;
    color: white;
    border-color: #F25244;
  }
  .delete-confirm-button:focus {
    outline: none;
    box-shadow: none;
  }
  .delete-cancel-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: #04BF8A;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color: white;
  }
  .delete-cancel-button:hover {
    background: #025940;
    color: white;
  }
  .delete-cancel-button:focus {
    outline: none;
    box-shadow: none;
  }
  .delete-button-icon {
    font-style: normal;
  }
  .delete-warning {
    font-size: large;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0px;
    text-align: center;
    padding-right: 10px;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .secondary-button-default {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: white;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 2px;
    border-style: solid;
    color:#282c34;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    transition: .3s;
  }
  .secondary-button-default:hover {
    background: #000000;
    color: white;
  }
  .secondary-button-default:focus {
    outline: none;
    box-shadow: none;
  }

  .secondary-button-red {
    margin: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: large;
    background: white;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: 2px;
    border-style: solid;
    color:#F25244;
  }
  .secondary-button-red:hover {
    background: #F25244;
    color: white;
  }
  .secondary-button-red:focus {
    outline: none;
    box-shadow: none;
  }

  .primary-button-green {
    font-size: large;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background:#04BF8A;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:white;
  }
  .primary-button-green:hover {
    background:#025940;
    color: white;
  }
  .primary-button-green:focus {
    outline: none;
    box-shadow: none;
  }

  .primary-button-darkgray {
    font-size: large;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background:#404040;
    min-height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: none;
    color:white;
  }
  .primary-button-darkgray:hover {
    background:#727272;
    color: white;
  }
  .primary-button-darkgray:focus {
    outline: none;
    box-shadow: none;
  }


  .dark-mode-secondary-button {
    font-size: large;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px;
    background: none;
    height: 36px;
    min-width: 110px;
    border-radius: 18px;
    outline: none;
    border: solid;
    border-width: 1px;
    color:#ECECEC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .dark-mode-secondary-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  .dark-mode-secondary-button:disabled:hover {
    background-color: gray;
    color: #ECECEC;
  }
  
  .dark-mode-secondary-button:hover {
    background: #afafaf;
    color: black;
  }
  .dark-mode-secondary-button:focus {
    outline: none;
    box-shadow: none;
  }



