.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.help-icon-header {
  display: block;
  font-size: large;
  color: white;
  cursor: pointer;
}
.help-icon-header-padded {
  padding-left: 1rem;
}

.footer {
  background: rgb(41,41,41);
  min-height: 6rem;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-shrink: 0;
}
.footer-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text{
  font-size: medium;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.show>.dropdown-menu {
  display: block;
  position: absolute;
  top: 85%;
}

.page-section-smoke {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #ECECEC;
  background: #ECECEC;
}

.App-header {
  background-color: rgb(41,41,41);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-navbar {
  background-color: rgb(41,41,41);
  background: rgb(41,41,41);
}

.navbar-content {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}



.app-logo-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0.25rem;
  color: white;
}

.nav-ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-burger::before{
  height: 30px;
}
.nav-burger{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-self: center;
  height: 100%;
  transition: .3s;
}

.nav-burger-menu-div{
  display: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 10px;
}
.nav-burger-menu-div:hover{
  background-color: azure;
  color: #404040;
  box-shadow: 0 0 5px 0 rgb(20, 20, 20);
}

.scrim{
  z-index: 999;
  background-color: transparent;
  position: fixed;
  top: 0%;
  height: 100vh;
  width: 100vh;
  display: none;
}

.navbar-logo {
  max-height: 45px;
  padding: 5px;
}

.dropdown-menu-right {
  background: #727272;
}

@media screen and (max-width: 750px) {
  .scrim{
    display: block;
  }
  .nav-ul{
    display: block;
    align-items: center;
    justify-items: center;
    position: fixed;
    top: -1000px;
    background-color: #72727287;
    left: 0rem;
    right: 0rem;
    min-height: 25vh;
    backdrop-filter: blur(5px);
    border-radius: 15px;
    padding: 10px;
    transition: .4s;
    z-index: 1000;
    
  }
  .nav-shown{
    top: 4rem;
    width: 100%;
    height: 100vh;
    left: 0;
  }
  .nav-dropdown{
    width: 80%;
    min-height: 3rem;
    backdrop-filter: blur(5px);
    background-color: #404040d8 !important;
    background: #404040d8 !important;
    color: white ;
    border-radius: 15px;
    border: solid !important;
    border-width: 1px !important;
    border-color: #ECECEC;
    margin: 10px
  }
  .dropdown-menu-right{
    width: 80%;
    position: relative;
    right: 10.5%;
  }
  .nav-burger-menu-div {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: block;
    position: relative;
    right: 0px;
  }
  .navbar-logo {
    max-height: 40px;
  }
  .help-icon-header {
    font-size: xx-large;
    padding-top: 1rem;
    padding-left: 0rem !important;
  }
}

.nav-dropdown {
  background-color: #404040;
  background: rgb(41,41,41);
  border: none;
  outline: none;
  margin-right: 1rem;
}
.nav-dropdown:active {
  outline: none;
}
.nav-dropdown-item {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  color: #ececec;
}

.nav-dropdown-item>icon-centered {
  color: #FFE11E;
}

.nav-dropdown-item:hover {
  background: #FFE11E;
}
.menu-link{
  text-decoration: none !important;
}
.home-icon {
  font-size: xx-large;
  font-style: inherit;
  color: #ECECEC;
  padding-right: 15px;
}
.home-span {
  font-size: medium;
  padding-left: 5px;

}

