.term-section-header {
    font-size: x-large;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    color: orangered;
}
.term-header {
    font-size: large;
    padding-top: 1rem;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    padding-left: 1rem;
    color: #ECECEC;
}
.term-page-header {
    font-size: x-large;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    color: #ECECEC;
}
.term {
    font-size: medium;
    text-align: left;
    padding-left: 1rem;
    white-space: pre-wrap;
    color: #cfcfcf;
}