.ai-teaser {
    margin : .5rem;
    margin-top: 1.5rem;
    background-color: None;
    border-style: solid;
    border-color: #727272;
    border-width: 1px;
    border-radius: 15px;
    min-height: 2rem;
}
.ai-teaser > h3 {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 350;
    font-size: x-large;
    text-align: left;
    padding: .5rem;
    padding-left: 1rem;
}
.ai-teaser > h3 > i {
    color: #ffea62;
}
.ai-teaser > p {
    color: #ececec;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.ai-teaser-button-container {
    display: flex;
    justify-content: flex-end;
    justify-items: flex-end;
}